import React from 'react';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import {Box, Divider, Stack, Typography} from '@mui/material';

import UsersIcon from '../../../assets/images/commons/users.svg?react';
import ContractIcon from '../../../assets/images/commons/contract.svg?react';
import ContactIcon from '../../../assets/images/commons/contact.svg?react';
import PaymentsIcon from '../../../assets/images/commons/payments.svg?react';
import SalaryIcon from '../../../assets/images/commons/salary.svg?react';
import AuditIcon from '../../../assets/images/commons/audit.svg?react';
import {useTranslation} from 'react-i18next';
import Pressable from '../../_commons/components/Pressable/Pressable';
import ModificationBanner from '../components/ModificationBanner';
import {useHasOneOfPermission} from '../../../hooks/useWebProPermissions';
import {Permission} from '../../../redux/@types/WebProContractTypes';

const SelfAdminHome = () => {
    const {t} = useTranslation(['commons']);

    const hasSalaryPermission = useHasOneOfPermission([
        Permission.PAYMENT_MANAGE_SALARY,
    ]);

    return (
        <Box paddingLeft={'10px'} paddingRight={'10px'}>
            <ModificationBanner/>

            <Box mt={12} paddingLeft={'10px'} paddingRight={'10px'}>
                <Grid container spacing={8}>
                    <Grid xs={12} md={6}>
                        <SelfAdminHomeItem
                            title={t('commons:modules.webpro.self-admin.menu.user-access')}
                            icon={<UsersIcon width={70} height={70}/>}
                            to={'./users'}
                        />
                    </Grid>

                    <Grid xs={12} md={6}>
                        <SelfAdminHomeItem
                            title={t('commons:modules.webpro.self-admin.menu.contact')}
                            icon={<ContactIcon width={70} height={70}/>}
                            to={'./contact'}
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        <SelfAdminHomeItem
                            title={t('commons:modules.webpro.self-admin.menu.payments')}
                            icon={<PaymentsIcon width={70} height={70}/>}
                            to={'./payments/standard-payments'}
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        <SelfAdminHomeItem
                            title={t('commons:modules.webpro.self-admin.menu.contract-label')}
                            icon={<ContractIcon width={70} height={70}/>}
                            to={'./contract'}
                        />
                    </Grid>
                    {hasSalaryPermission &&
                        <Grid xs={12} md={6}>
                            <SelfAdminHomeItem title={t('commons:modules.webpro.self-admin.menu.payments-salary')}
                                               icon={<SalaryIcon width={70} height={70}/>}
                                               to={'./payments/salary-payments'}/>
                        </Grid>
                    }
                    <Grid xs={12} md={6}>
                        <SelfAdminHomeItem
                            title={t('commons:modules.webpro.self-admin.menu.audit-log')}
                            icon={<AuditIcon width={70} height={70}/>}
                            to={'./audit'}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

const SelfAdminHomeItem = ({
                               title,
                               icon,
                               to
                           }: {
    title: string;
    icon: React.FunctionComponentElement<React.SVGProps<SVGSVGElement>>;
    to: string;
}) => {
    return (
        <Pressable to={to} sx={{p: 4}}>
            <Stack
                direction={'row'}
                alignItems={'center'}
                spacing={8}
                divider={
                    <Divider
                        sx={{
                            background: 'none',
                            border: 'none',
                            width: '1px',

                            backgroundImage:
                                'linear-gradient(#707070 8%, rgba(255,255,255,0) 0%)',
                            backgroundPosition: 'right',
                            backgroundSize: '1px 8px',
                            backgroundRepeat: 'repeat-y'
                        }}
                        orientation="vertical"
                        variant={'middle'}
                        flexItem
                    />
                }
            >
                {icon}
                <Typography variant={'h4'}>{title}</Typography>
            </Stack>
        </Pressable>
    );
};

export default SelfAdminHome;
